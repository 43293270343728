<template>
  <ion-page>
    <ion-content class="ion-padding-start ion-padding-end">
      <div
        class="main-content is-flex is-flex-direction-column h-100"
      >
        <div
          class="
            is-flex
            ion-align-items-center ion-justify-content-center
            is-flex-direction-column
            pt-6
          "
        >
          <IonImg
            src="./assets/medicast-logo.png"
            style="height: 100px"
          />

          <h2 class="title fw-900 mb-3">Medicast</h2>
          <h3 class="subtitle fw-700">MedischeScholing</h3>
          <h5
            v-if="platform === 'web'"
            class="fw-400"
          >{{ $t('invitationCard.inviteCode') }}</h5>
        </div>


        <div
          v-if="platform === 'web'"
          class="
            login-form
            is-flex is-flex-direction-column
            ion-justify-content-between ion-align-items-center"
        >
          <div class="card-wrap">
            <ion-card color="medium ion-padding ion-margin is-flex">
              <h2 class="token-text ion-text-center">{{ $route.params.inviteToken }}</h2>
              <IonIcon
                class="pointer"
                :icon="copyOutline"
                @click="copy($route.params.inviteToken, $t('invitationCard.successMessage'))"
              />
            </ion-card>
            <h5 class="fw-400 mb-3 ion-text-center">{{ $t('invitationCard.downloadApp') }}</h5>
          </div>

          <div class="buttons-wrap ion-text-center">
            <IonImg
              src="./assets/google-play-badge.png"
              style="width: 270px"
              class="ion-margin-bottom pointer"
              @click.prevent="openAndroidUrl"
            />

            <IonImg
              src="./assets/apple-ios-badge.png"
              class="pointer"
              style="width: 270px"
              @click.prevent="openAppleUrl"
            />
            <ion-button
              mode="ios"
              fill="clear"
              class="uppercase fw-500 mt-5 fz-12 contact-us"
              @click.prevent="openUrl('https://app.medicast.nl/contact')"
            >
              {{ $t('contact.us') }}
            </ion-button>
          </div>
        </div>
        <div
          v-else
          class="installed-app ion-text-center ion-padding-top"
        >
          <h5 class="ion-padding-bottom">{{ $t('invitationCard.installedApp') }}</h5>
          <div class="ion-padding-bottom">
            <ion-button router-link="/main/home">
              {{ $t('commonKeys.open') }} Medicast
            </ion-button>
          </div>
          <div>
            <ion-button
              fill="outline"
              @click="openModal"
            >
              {{ $t('invitationCard.title') }}
            </ion-button>
          </div>
        </div>
        <div
          class="logo-wrap is-flex ion-justify-content-center ion-align-items-end"
          :style="{flexGrow: platform === 'web' ? 1 : 0}"
        >
          <ion-img
            src="./assets/logo.png"
            style="height: 100px"
          />
        </div>

      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import AppInput from '@/components/common/AppInput.vue';

import { mailOutline, lockClosedOutline, copyOutline } from 'ionicons/icons';
import { IonContent, IonPage, IonButton, IonLoading, IonImg, IonCard, IonIcon, modalController } from '@ionic/vue';
import useCopyToClipboard from '@/composables/useCopyToClipboard';
import useInAppBrowser from '@/composables/useInAppBrowser';
import ShareModal from '@/components/common/ShareModal';
import { computed } from '@vue/reactivity';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n/index';
import axios from '@/http';
import { GET_INVITE_TOKEN } from '@/config/api';

export default {
  name: 'DownloadApp',
  components: {
    AppInput,
    IonContent,
    IonPage,
    IonButton,
    IonLoading,
    IonImg,
    IonCard,
    IonIcon,
  },

  setup() {
    const { copy } = useCopyToClipboard();
    const { openUrl } = useInAppBrowser();
    const store = useStore();
    const { t } = useI18n();

    const platform = computed(() => store.state.app.platform);

    const openAndroidUrl = () => openUrl(process.env.VUE_APP_ANDROID_STORE);
    const openAppleUrl = () => openUrl(process.env.VUE_APP_IOS_STORE);

    const openModal = () => {
      getInviteToken().then((token) => {
        openShareModal(token);
      });
    };

    const openShareModal = async (token) => {
      const modal = await modalController.create({
        component: ShareModal,
        componentProps: {
          shareLink: `${process.env.VUE_APP_SHARE_URL}/invite/${token}`,
          shareText:  t('invitationCard.shareText', { token }),
          showLink: false,
          modalTitle: t('invitationCard.shareInvite'),
        },
        cssClass: 'share-modal',
      });
      await modal.present();
    };

    const getInviteToken = () => {
      return new Promise((resolve, reject) => {
        axios
          .get(GET_INVITE_TOKEN)
          .then(({ data }) => {
            resolve(data.invite_token);
          })
          .catch((error) => {
            reject(error);
          });
      });
    };

    return {
      mailOutline,
      lockClosedOutline,
      copyOutline,
      copy,
      openUrl,
      openAndroidUrl,
      openAppleUrl,
      platform,
      openModal,
    };
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 50px;
}
.subtitle {
  font-size: 20px;
  letter-spacing: 2.7px;
  margin-bottom: 20px;
}

.forgot-btn {
  color: var(--white);
  text-decoration: none;
}

.img {
  height: 105px;
}

.gradient-btn-haweb {
  --border-radius: 20px;
  --background: linear-gradient(
    180deg,
    rgba(122, 180, 29, 1) 0%,
    rgba(108, 161, 25, 1) 100%
  );
}

.logo-wrap {
  margin: 10px 0 20px;
}
ion-icon {
  font-size: 24px;
}
.token-text {
  width: 100%;
}
.card-wrap {
  width: 300px;
}
.buttons-wrap {
  width: 270px;
}

.installed-app {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media screen and (max-width: 320px) {
  .title {
    font-size: 60px;
  }
}
</style>
